<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">
      <h5 class="text-center mb-5">Выставление оценок за практику</h5>

      <!-- filter section -->
      <!--      <div class="row mt-4">-->
      <!--        <label class="col-md-3 col-form-label">Курс</label>-->
      <!--        <div class="col-md-9">-->
      <!--          <select class="form-control form-select" v-model="studyCourse">-->
      <!--            <option v-for="(sCourse, sCourseIndex) in studyCourses" :key="sCourseIndex"-->
      <!--                    :value="sCourse.value">-->
      <!--              {{sCourse.name}}-->
      <!--            </option>-->
      <!--          </select>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Уровень обучения</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="studyLevelId">
            <option :value="0" hidden>Выберите</option>
            <option v-for="(item, index) in adviserChoose_form.studyLevels" :key="index"
                    :value="item.id">
              {{item.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4" v-if="studyLevelId">
        <label class="col-md-3 col-form-label">Образовательная программа</label>
        <div class="col-md-9" v-if="!loadingPrograms">
          <select class="form-control form-select" v-model="educationProgramId">
            <option :value="0" hidden>Выберите</option>
            <option v-for="(item, index) in graduateWorkAttestation.programs" :key="index"
                    :value="item.id">
              {{item.education_speciality_code}} {{item.education_speciality_name}} -
              {{item.language_name}} - {{item.admission_year}}
            </option>
          </select>
        </div>
      </div>


      <div v-if="educationProgramId&&!loadingGroup">
        <div class="row mt-4">
          <div class="col-md-3">Группы</div>
          <div class="col-md-9">
            <div v-if="adviserChoose_form.studentGroups.length">
              <select class="form-control form-select" v-model="groupId">
                <option :value="0" hidden>Выберите</option>
                <option v-for="(item, index) in adviserChoose_form.studentGroups" :key="index" :value="item.id">
                  {{item.name}}
                </option>
              </select>
            </div>
            <div v-else class="text-danger">
              В данной образовательной программе нет групп
            </div>
          </div>
        </div>


        <div class="row mt-4">
          <div class="col-md-3">Практика</div>
          <div class="col-md-9">
            <div v-if="practiceDisciplines.length">
              <select class="form-control form-select" v-model="practiceFormationId">
                <option :value="0" hidden>Выберите</option>
                <option v-for="(item, index) in practiceDisciplines" :key="index"
                        :value="item.formation_education_program_id">
                  {{item?.discipline_name}} (Кред. {{item?.credit}}, Семестр {{item?.semester}})
                </option>
              </select>
            </div>
            <div v-else class="text-danger">
              В данной образовательной программе нету практики
            </div>
          </div>
        </div>

        <div class="my-4 text-center" v-if="groupId&&practiceFormationId">
          <button type="button" class="btn btn-primary" @click="getPracticeGrades">
            Показать
          </button>
        </div>

      </div>


      <!-- end filter section -->


      <!-- select members section -->
      <div v-if="loadingGroupData" class="d-flex justify-content-center my-5">
        <Preloader/>
      </div>
      <div class="my-4"
           v-else-if="isGroupDataLoaded&&Object.keys(practiceGrades).length&&groupId&&practiceFormationId">

        <div v-if="practiceGrades.length">

          <DataTable :value="practiceGrades" showGridlines stripedRows responsiveLayout="scroll">

            <Column header="Баркод" field="barcode"></Column>
            <Column header="ФИО">
              <template #body="{data}">
                {{data?.last_name}} {{data?.first_name}}
              </template>
            </Column>
            <Column header="Оценка">
              <template #body="{data}">
                <div v-if="data?.id" class="row">
                  <div class="col-md-6">
                    <input class="form-control" type="number" max="100" min="0" placeholder="Оценка"
                           :disabled="!data?.edit_status"
                           v-model="data.score">
                  </div>
                  <div class="col-md-6">
                    <Button v-if="data?.edit_status" icon="pi pi-check" class="p-button-rounded mx-1"
                            :disabled="btnSaveStatus"
                            @click="putPracticeGrade(data.id)"/>
                    <Button v-else icon="pi pi-pencil" class="p-button-rounded p-button-warning mx-1"
                            :disabled="btnSaveStatus"
                            @click="data.edit_status = true"/>
                  </div>
                </div>
                <input v-else class="form-control" type="number" max="100" min="0" placeholder="Оценка"
                       v-model="data.score">
              </template>
            </Column>

          </DataTable>


          <div class="my-4 text-center" v-if="practiceGrades.length&&practiceGrades.some(i=>!i.id)">
            <button type="button" class="btn btn-primary" @click="savePracticeGrades" :disabled="btnSaveStatus">
              <span v-if="btnSaveStatus" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              {{btnSaveStatus ? 'Сохранение' : 'Сохранить'}}
            </button>
          </div>

        </div>
        <div v-else>Оценки не найдены</div>


      </div>
      <!-- end select members section -->


    </div>

  </div>

</template>


<script>

  import {mapGetters, mapActions, mapMutations, mapState} from 'vuex'
  import httpClient from "@/services/http.service"

  export default {
    name: "StudentPracticeGrades",
    data() {
      return {
        loading: true,
        studyLevelId: 0,
        studyCourse: 0,
        educationProgramId: 0,
        groupId: 0,
        loadingGroup: false,
        loadingGroupData: false,
        isGroupDataLoaded: false,
        btnSaveStatus: false,
        attestationMembers: {},
        practiceDisciplines: [],
        practiceFormationId: 0,
        practiceGrades: []
      }
    },
    computed: {
      ...mapState('adviserChoose', ['adviserChoose_form']),
      ...mapState('graduateWorkAttestation', ['graduateWorkAttestation']),
      checkMembers() {
        return Object.values(this.attestationMembers).some(i => !i)
      }
    },
    watch: {
      studyCourse() {
        this.getEducationPrograms()
        this.isGroupDataLoaded = false
      },
      studyLevelId() {
        this.loadingPrograms = true
        this.getEducationPrograms()
        this.groupId = 0
        this.practiceFormationId = 0
        this.isGroupDataLoaded = false
        this.loadingPrograms = false
      },
      async educationProgramId(id) {
        this.loadingGroup = true
        this.practiceFormationId = 0
        await this.GET_STUDENT_GROUPS_BY_EDUCATION_PROGRAM_ID(id)
        await this.getPracticeDisciplines(id)
        this.loadingGroup = false
        this.isGroupDataLoaded = false
      },
      groupId() {
        this.isGroupDataLoaded = false
      },
      practiceFormationId() {
        this.isGroupDataLoaded = false
      }
    },
    methods: {
      ...mapActions('adviserChoose', [
        'GET_STUDY_LEVELS', 'GET_EDUCATION_PROGRAMS',
        'GET_STUDENT_GROUPS_BY_EDUCATION_PROGRAM_ID', 'GET_PPS'
      ]),
      ...mapActions('graduateWorkAttestation', [
        'GET_GRADUATE_WORK_USERS_BY_GROUP',
        'POST_GRADUATE_WORK_USERS',
        'PUT_GRADUATE_WORK_USERS',
        'GET_TEACHERS',
        'GET_LAST_EDUCATION_PROGRAMS'
      ]),
      getEducationPrograms() {
        if (this.studyLevelId) {
          this.GET_LAST_EDUCATION_PROGRAMS(this.studyLevelId)
        }
      },
      async getPracticeDisciplines(educationProgramId) {
        try {
          const {status, data} = await httpClient.get(`education_program/formation-education-program/get-practice-disciplines?education_program_id=${educationProgramId}`)
          if (status === 200) {
            this.practiceDisciplines = data
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
      },
      async getPracticeGrades() {
        this.loadingGroupData = true
        try {
          const {status, data} = await httpClient.get(`student/student-practice-ratings/get-practice-grades?group_id=${this.groupId}&formation_education_program_id=${this.practiceFormationId}`)
          if (status === 200) {
            this.practiceGrades = data
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
        this.loadingGroupData = false
        this.isGroupDataLoaded = true
      },
      async savePracticeGrades() {
        this.btnSaveStatus = true

        const practice = this.practiceDisciplines.find(i => i.formation_education_program_id == this.practiceFormationId)

        if (practice) {
          const postData = this.practiceGrades
            .filter(i => !i.id)
            .map(i => ({
              student_id: i.student_id,
              formation_education_program: practice.formation_education_program_id,
              semester: practice.semester,
              credit: practice.credit,
              score: i.score || 0
            }))

          try {
            const {status} = await httpClient.post(`student/student-practice-ratings/create`, postData)
            if (status === 200) {
              await this.getPracticeGrades()
              this.$message({text: 'Сохранено'})
            }
          } catch (e) {
            this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
          }
        }
        this.btnSaveStatus = false
      },
      async putPracticeGrade(id) {
        this.btnSaveStatus = true

        const putData = this.practiceGrades.find(i => i.id == id)

        if (putData) {
          try {
            const {status} = await httpClient.put(`student/student-practice-ratings/update?id=${id}`, putData)
            if (status === 200) {
              await this.getPracticeGrades()
              this.$message({text: 'Изменено'})
            }
          } catch (e) {
            this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
          }
        }
        this.btnSaveStatus = false
      },
    },
    async mounted() {
      await this.GET_STUDY_LEVELS()
      // await this.GET_PPS()
      await this.GET_TEACHERS()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>